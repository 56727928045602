import { location } from '@townsquare/facade';

type Config = typeof stagingConfig & {
  env: Env;
};

const useProxy = (function () {
  // Inlined by webpack at build time
  if (typeof __USE_PROXY__ !== 'undefined') {
    return __USE_PROXY__;
  }

  // Inlined by Parcel at buld time
  if (typeof process.env.__USE_PROXY__ !== 'undefined') {
    return process.env.__USE_PROXY__;
  }

  return false;
})();

const buildDev = (function () {
  // Inlined by webpack at build time
  if (typeof __BUILD_DEV__ !== 'undefined') {
    return __BUILD_DEV__;
  }

  // Inlined by Parcel at buld time
  if (typeof process.env.__BUILD_DEV__ !== 'undefined') {
    return process.env.__BUILD_DEV__;
  }

  return false;
})();

export const SITE_IDENTIFIER_PLACEHOLDER = ':siteIdentifier';

const stagingConfig = {
  // Non-TC gateway api urls
  atlassianGQLGatewayUrl: '/gateway/api/graphql',
  directoryServiceUrl: '/gateway/api/directory',
  emojiUrl: '/gateway/api/emoji',
  profileCardUrl: '/gateway/api/directory/graphql',
  availableProductsUrl: '/gateway/api/available-products',
  appSwitcherBffUrl: '/gateway/api/app-switcher',
  collabGraphUrl: '/gateway/api/collaboration',
  notificationsUrl: '/gateway/api/notification-log',
  searchUrl: '/gateway/api/xpsearch-aggregator',
  // TC gateway api urls
  authCheckUrl: '/gateway/api/watermelon/isAuthenticated',
  orgHasWorkspaceUrl: '/gateway/api/watermelon/organization/containsAnyWorkspaceByHost',
  watermelonUrl: '/gateway/api/watermelon',
  watermelonGraphQLUrl: '/gateway/api/watermelon/graphql',
  townsquareUrl: `/gateway/api/townsquare/s/${SITE_IDENTIFIER_PLACEHOLDER}`,
  townsquareGraphQLUrl: `/gateway/api/townsquare/s/${SITE_IDENTIFIER_PLACEHOLDER}/graphql`,
  aggGraphQLUrl: '/gateway/api/graphql',
  mediaUploadTokenPath: '/media-upload-token',
  mediaRendererTokenPath: '/media-token',
  reactionsPath: '/reactions',
  userCapabilitiesUrl: '/gateway/api/watermelon/user/capabilities',
  exportProjectCsvByTqlPath: '/export/projects/tql',
  exportGoalCsvByTqlPath: '/export/goals/tql',
  haveISeenItUrl: '/gateway/api/flag/my',
  // Webpack entry point urls
  fullUrl: 'https://team.stg.atlassian.com',
  productSmartCardBaseUrl: 'https://team.stg.atlassian.com',
  homeSmartCardBaseUrl: 'https://home.stg.atlassian.com',
  classicUrl: 'https://team.stg.atlassian.com',
  homeUrl: 'https://home.stg.atlassian.com',
  jiraIntegrationUrl: '/integration/jira',
  msteamsIframeIntegrationUrl: '/integration/msteams',
  // External urls
  atlassianAccountUrl: 'https://id.stg.internal.atlassian.com',
  gasApiHost: 'team.stg.atlassian.com/gateway/api/gasv3/api/v1',
  wacUrl: 'https://qa-wac.internal.atlassian.com',
  mediaBaseUrl: 'https://media.staging.atl-paas.net',
  expandUrl: 'https://team.stg.atlassian.com/your-work?startTrialProduct=townsquare&edition=free',
  jiraConnectExpandUrl:
    'https://team.stg.atlassian.com/your-work?startTrialProduct=townsquare&edition=free&sourceContext=atlas-jira-connect&targetTab=same',
  learnMoreUrl: 'https://www.atlassian.com.atl.zbizdev.zengel.myshn.net/software/team-central',
  adminHubUrl: 'https://admin.stg.atlassian.com',
  adminHubSitePath: '/s',
  adminBillingPath: '/billing',
  billingPreviewPath: '/billing-preview',
  macUrl: 'https://my.stg.internal.atlassian.com',
  supportUrl: 'https://support.stg.internal.atlassian.com/',
  termsOfUseUrl: 'https://www.atlassian.com.atl.zbizdev.zengel.myshn.net/legal/cloud-terms-of-service',
  privacyPolicyUrl: 'https://www.atlassian.com.atl.zbizdev.zengel.myshn.net/legal/privacy-policy#what-this-policy-covers',
  internalPrivacyPolicyUrl:
    'https://hello.atlassian.net.atl.zbizdev.zengel.myshn.net/wiki/spaces/PMP/pages/139161942/Standard+-+Global+Workplace+Privacy+Notice',
  noticeAtCollectionUrl: 'https://www.atlassian.com.atl.zbizdev.zengel.myshn.net/legal/privacy-policy#additional-disclosures-for-ca-residents',
  internalNoticeAtCollectionUrl:
    'https://hello.atlassian.net.atl.zbizdev.zengel.myshn.net/wiki/spaces/PMP/pages/139161942/Standard+-+Global+Workplace+Privacy+Notice#13.-Additional-Disclosures-for-California-Residents',
  internalKudosPolicyUrl:
    'https://hello.atlassian.net.atl.zbizdev.zengel.myshn.net/wiki/spaces/GDAY/pages/1604155950/Global+-+Kudos+Peer-to-Peer+Recognition',
  // Integrations
  msteamsIntegrationUrl: 'http://teamcentral-msteams-integration.stg.atlassian.com',
  slackIntegrationUrl: 'http://teamcentral-slack-integration.stg.atlassian.com',
  milestonesBestPracticesUrl:
    'https://hello.atlassian.net.atl.zbizdev.zengel.myshn.net/wiki/spaces/STATUS/pages/2914836294/How+to+best+use+Atlas+milestones+in+projects',
  // Non-FF Feature controls
  enableIntercom: true,
  ffsBaseUrl: 'https://api.stg.atlassian.com/flags',
};

type EnvironmentOverrides = { [env in Env]?: Partial<Config> };

const getLocalOverrides = () => {
  const baseOptions = {
    directoryServiceUrl: 'https://team.stg.atlassian.com/gateway/api/directory',
    mediaBaseUrl: 'https://media.staging.atl-paas.net',
    enableIntercom: true,
    msteamsIntegrationUrl: 'http://teamcentral-msteams-integration.stg.atlassian.com',
    // Local development will have to use staging links in order for smart link to resolve properly
    productSmartCardBaseUrl: 'https://team.stg.atlassian.com',
    homeSmartCardBaseUrl: 'https://home.stg.atlassian.com',
  };

  const localBackendUrls = {
    fullUrl: `http://${location().hostname}:${location().port}`,
  };

  return useProxy ? baseOptions : { ...baseOptions, ...localBackendUrls };
};

// TS does not distinguish between missing keys and keys with undefined values.
// Don't specify null/undefined for any config values or the object spread will give you an invalid Config in getConfig()
// https://github.com/Microsoft/TypeScript/issues/13195
const environmentOverrides: EnvironmentOverrides = {
  local: getLocalOverrides(),
  'prod-east': {
    atlassianAccountUrl: 'https://id.atlassian.com.atl.zbizdev.zengel.myshn.net',
    fullUrl: 'https://team.atlassian.com.atl.zbizdev.zengel.myshn.net',
    productSmartCardBaseUrl: 'https://team.atlassian.com.atl.zbizdev.zengel.myshn.net',
    homeSmartCardBaseUrl: 'https://home.atlassian.com',
    gasApiHost: 'team.atlassian.com.atl.zbizdev.zengel.myshn.net/gateway/api/gasv3/api/v1',
    mediaBaseUrl: 'https://api.media.atlassian.com.atl.zbizdev.zengel.myshn.net',
    wacUrl: 'https://www.atlassian.com.atl.zbizdev.zengel.myshn.net',
    msteamsIntegrationUrl: 'http://teamcentral-msteams-integration.atlassian.com',
    slackIntegrationUrl: 'https://teamcentral-slack-integration.atlassian.com',
    expandUrl: 'https://team.atlassian.com.atl.zbizdev.zengel.myshn.net/your-work?startTrialProduct=townsquare&edition=free',
    jiraConnectExpandUrl:
      'https://team.atlassian.com.atl.zbizdev.zengel.myshn.net/your-work?startTrialProduct=townsquare&edition=free&sourceContext=atlas-jira-connect&targetTab=same',
    adminHubUrl: 'https://admin.atlassian.com.atl.zbizdev.zengel.myshn.net',
    macUrl: 'https://my.atlassian.com.atl.zbizdev.zengel.myshn.net',
    termsOfUseUrl: 'https://www.atlassian.com.atl.zbizdev.zengel.myshn.net/legal/cloud-terms-of-service',
    privacyPolicyUrl: 'https://www.atlassian.com.atl.zbizdev.zengel.myshn.net/legal/privacy-policy#what-this-policy-covers',
    internalPrivacyPolicyUrl:
      'https://hello.atlassian.net.atl.zbizdev.zengel.myshn.net/wiki/spaces/PMP/pages/139161942/Standard+-+Global+Workplace+Privacy+Notice',
    noticeAtCollectionUrl: 'https://www.atlassian.com.atl.zbizdev.zengel.myshn.net/legal/privacy-policy#additional-disclosures-for-ca-residents',
    internalNoticeAtCollectionUrl:
      'https://hello.atlassian.net.atl.zbizdev.zengel.myshn.net/wiki/spaces/PMP/pages/139161942/Standard+-+Global+Workplace+Privacy+Notice#13.-Additional-Disclosures-for-California-Residents',
    internalKudosPolicyUrl:
      'https://hello.atlassian.net.atl.zbizdev.zengel.myshn.net/wiki/spaces/GDAY/pages/1604155950/Global+-+Kudos+Peer-to-Peer+Recognition',
    supportUrl: 'https://support.atlassian.com/',
    classicUrl: 'https://team.atlassian.com.atl.zbizdev.zengel.myshn.net',
    homeUrl: 'https://home.atlassian.com',
    ffsBaseUrl: 'https://api.atlassian.com/flags',
  },
};

const ACCEPTABLE_BIFROST_DOMAIN: { [key in Env]?: string[] } = {
  'stg-east': ['townsquare-bifrost.stg.atlassian.com', 'home.stg.atlassian.com'],
  'prod-east': ['townsquare-bifrost.atlassian.com', 'home.atlassian.com'],
};
function getBifrostOverride(env: Env) {
  if (isBifrost()) {
    const currentDomain = window.location.hostname;
    const acceptableDomains = ACCEPTABLE_BIFROST_DOMAIN[env] ?? [];

    if (acceptableDomains.includes(currentDomain)) {
      // Currently browsing Atlas bifrost from an acceptable domain, then continue as is, no need to redirect
      return { fullUrl: `https://${currentDomain}`, gasApiHost: `${currentDomain}/gateway/api/gasv3/api/v1` };
    } else {
      // Else redirect to a bifrost site
      const isProd = env === 'prod-east';
      return {
        fullUrl: isProd ? 'https://townsquare-bifrost.atlassian.com' : 'https://townsquare-bifrost.stg.atlassian.com',
      };
    }
  }
  return {};
}

export function getEnv(): Env {
  if (buildDev) {
    return 'local';
  }

  const env = window.__env__;

  if (!env) {
    console.warn('config: No environment override found.');
  }

  return env ?? 'unknown';
}

function isBifrost(): boolean {
  if (buildDev) {
    return false;
  }

  return window.__bifrost__ ?? false;
}

export function getReleaseVersion(): string {
  if (buildDev) {
    return 'local';
  }
  const versionMeta: HTMLMetaElement | null = document.querySelector('meta[name="release-version"]');
  if (!versionMeta) {
    return 'noversion';
  } else {
    return versionMeta.content || 'noversion';
  }
}

export function getConfig(): Config {
  const env = getEnv();
  const bifrostOverride = getBifrostOverride(env);

  return {
    env,
    ...stagingConfig,
    ...environmentOverrides[env],
    ...bifrostOverride,
  };
}

export function isInIframe(): boolean {
  try {
    return window.self !== window.top;
  } catch (e) {
    return true;
  }
}

export function isEmbedOrIntegration() {
  return /(\/embeds\/)|(\/integration\/)/gi.test(window.location.pathname);
}

export function isIntegration() {
  return /(\/integration\/)/gi.test(window.location.pathname);
}

export function isEmbed() {
  return /(\/embeds\/)/gi.test(window.location.pathname);
}

const ENV_TO_IDENTITY_ENV: Record<Env, 'dev' | 'staging' | 'prod'> = {
  ddev: 'staging',
  test: 'staging',
  local: 'staging',
  'stg-east': 'staging',
  'prod-east': 'prod',
  unknown: 'prod',
};

export const getIdentityEnv = (env: Env) => ENV_TO_IDENTITY_ENV[env] || 'prod';
