import * as Sentry from '@sentry/react';

import { isUsingDefaultUrl } from '@townsquare/auth-utils';
import { getEnv, getReleaseVersion } from '@townsquare/config';

import { sentryFilterEvents, filterBreadcrumb } from './utils';

const sentryDSN = 'https://8fc705dbdd6642369d8cd2dd00b97219@o55978.ingest.sentry.io/5988862';

export function installSentry() {
  if (!__BUILD_PROD__) {
    // Only enable sentry in stg or prod
    return;
  }

  if (isUsingDefaultUrl()) {
    // Only enable sentry when Atlas is accessed from the default URL
    Sentry.init({
      dsn: sentryDSN,
      environment: getEnv(),
      release: getReleaseVersion(),
      sampleRate: 1,
      maxBreadcrumbs: 20, // Only send 20 most recent breadcrumbs
      beforeSend: sentryFilterEvents,
      beforeBreadcrumb: filterBreadcrumb,
      ignoreErrors: [
        // A benign error, see https://stackoverflow.com/a/50387233/2645305
        'ResizeObserver loop limit exceeded',
        /ResizeObserver loop completed with undelivered notifications/,
        // Happens when trying to load an async chunk that can't be found and is redirected to the 404 page
        // The error isn't very actionable and we don't want to be alerted for it
        // Sentry even has a default filter to ignore these errors, but some browsers emit with a different name
        // https://github.com/getsentry/sentry-javascript/issues/6945
        `Unexpected token '<'`,
      ],
    });
  }
}

/**
 * REF: https://hello.atlassian.net.atl.zbizdev.zengel.myshn.net/wiki/spaces/SECURITY/pages/236070634/Can+I+Log+This
 * Also, #observability channel verified this is ok to log in sentry because we host Sentry.
 */
export function setSentryAAID(id: string) {
  Sentry.setUser({ id });
}

export function setSentryCloudId(cloudId: string) {
  Sentry.setTag('cloudId', cloudId);
}

export function addSentryBreadcrumb(crumb: Sentry.Breadcrumb) {
  Sentry.addBreadcrumb(crumb);
}
