import { token } from '@atlaskit/tokens';
import styled from 'styled-components';

import { ActionBar } from '@townsquare/action-bar';
import { ellipsis } from '@townsquare/ellipsis';
import { onMobile } from '@townsquare/responsive-grid';
import { UserAvatarStyle } from '@townsquare/user-avatar';

import { Card } from './Card';

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const ChangeCard = styled(Card)({
  boxShadow: 'none',
  border: `1px solid ${token('color.border')}`,
  marginBottom: token('space.200'),
  // eslint-disable-next-line @atlaskit/ui-styling-standard/no-important-styles -- Ignored via go/DSP-18766
  padding: `${token('space.100')} ${token('space.150')} !important`,
  '&:empty': {
    display: 'none',
  },
});

// TODO: DSP-2365 https://product-fabric.atlassian.net.atl.zbizdev.zengel.myshn.net/browse/DSP-2365 Convert box-shadow colors
// eslint-disable-next-line @atlaskit/design-system/ensure-design-token-usage, @atlaskit/ui-styling-standard/no-exported-styles, @atlaskit/design-system/no-styled-tagged-template-expression -- Ignored via go/DSP-18766
export const UpdateCard = styled.div<{ isFocused?: boolean }>`
  margin-bottom: ${token('space.500')};
  position: relative;

  &[animate='pulse'] {
    &:after {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      content: '';
      background: transparent;
      border-radius: 3px;
      box-shadow: 0 0 0 0 grey;
      animation: pulse 1.5s 3;
      animation-delay: 1s;
      z-index: -1;
    }
  }

  @keyframes pulse {
    0% {
      transform: scale(0.99, 0.96);
      box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.2);
    }

    70% {
      transform: scale(1.01, 1.04);
      box-shadow: 0 0 0 10px rgba(0, 0, 0, 0);
    }

    100% {
      transform: scale(0.99, 0.96);
      box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
    }
  }

  ${Card} {
    padding: ${token('space.250')};
  }

  > ${Card} {
    ${({ isFocused: isSelected }) => isSelected && `box-shadow: ${token('elevation.shadow.overlay')}`}
  }
`;

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const CardTop = styled.div({
  alignItems: 'center',
  display: 'flex',
  marginBottom: token('space.200'),
  flex: 1,
  justifyContent: 'space-between',
  flexWrap: 'wrap',
  // eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
  '> div': {
    display: 'flex',
    alignItems: 'center',
    // eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
    '> * + *': {
      marginLeft: token('space.100'),
    },
  },
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-exported-styles, @atlaskit/design-system/no-styled-tagged-template-expression -- Ignored via go/DSP-18766
export const Creator = styled.div`
  max-width: 100%;
  flex-wrap: wrap;

  ${UserAvatarStyle.UserAvatar} > div:first-child {
    display: flex;
  }

  ${UserAvatarStyle.Name} {
    margin: 0;
    font-weight: ${token('font.weight.medium')};
    color: ${token('color.text')};
  }

  ${UserAvatarStyle.BorderlessAvatar} {
    span {
      width: 28px;
      height: 28px;
    }
  }
`;

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const Summary = styled.div({
  font: token('font.body.large'),
  margin: `${token('space.150')} 0 ${token('space.150')} 0`,
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const CardFooter = styled(ActionBar)({
  marginTop: token('space.300'),
  marginBottom: token('space.150'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const CardHeader = styled.div({
  display: 'flex',
  justifyContent: 'space-between',
  marginBottom: token('space.100'),
  flex: 1,
  minWidth: 0,
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const CardHeaderInfo = styled.div({
  maxWidth: '100%',
  display: 'flex',
  alignItems: 'top',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const CardHeaderAction = styled.div({
  display: 'flex',
  alignItems: 'center',
  marginLeft: token('space.100'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const TypeAndName = styled.div({
  display: 'flex',
  flexDirection: 'column',
  marginLeft: token('space.100'),
  flex: 1,
  minWidth: 0,
  // eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
  sub: {
    font: token('font.heading.xxsmall'),
    color: token('color.text.subtlest'),
  },
  // eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
  a: {
    color: token('color.text'),
    font: token('font.heading.xsmall'),
    wordBreak: 'break-word',
    '&:hover': {
      color: token('color.text.subtlest'),
      textDecoration: 'none',
    },
  },
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-exported-styles, @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
export const Icon = styled.div<{ color?: string }>(props => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  // eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
  background: props.color,
  width: '32px',
  height: '32px',
  borderRadius: '3px',
}));

// eslint-disable-next-line @atlaskit/design-system/no-styled-tagged-template-expression, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const MissedUpdateLabel = styled.span<{ showDot?: boolean }>`
  color: ${token('color.text.subtlest')};
  font-weight: ${token('font.weight.medium')};
  display: flex;
  overflow: hidden;

  ${onMobile(`
    padding-bottom: ${token('space.100')};
  `)}

  > div {
    ${ellipsis}
  }

  ${props =>
    props.showDot
      ? `
            &:before {
              margin-right: ${token('space.100')};
              content: '\\2022';
            }
          `
      : ''}
`;

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const MissedUpdateMessage = styled.div({
  display: 'flex',
  justifyContent: 'space-between',
  padding: token('space.150'),
  backgroundColor: token('color.background.neutral'),
  borderRadius: '3px',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const UserNameAndByLine = styled.div({
  display: 'flex',
  flexDirection: 'column',
  marginLeft: token('space.150'),
  color: token('color.text.subtlest'),
  // eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
  'a, span, button, a:hover, button:hover': {
    // eslint-disable-next-line @atlaskit/ui-styling-standard/no-important-styles -- Ignored via go/DSP-18766
    color: `${token('color.text.subtlest')} !important`,
  },
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-exported-styles, @atlaskit/design-system/no-styled-tagged-template-expression -- Ignored via go/DSP-18766
export const UserNameByLine = styled.div`
  display: flex;
  font: ${token('font.body.UNSAFE_small')};
  font-weight: ${token('font.weight.medium')};

  // TODO (TC-5472): Set this margin directly on MissedUpdateLabel once Goal adopts the new layout
  ${MissedUpdateLabel} {
    &:before {
      margin-left: ${token('space.050')};
      margin-right: ${token('space.050')};
    }
  }
`;
