import { ButtonItem, MenuGroup, Section } from '@atlaskit/menu';
import React, { Suspense, useEffect } from 'react';
import { FormattedMessage } from 'react-intl-next';
import { PreloadedQuery } from 'react-relay';
import { useRouter } from 'react-resource-router';

import { useAnalytics } from '@townsquare/analytics';
import { useCreateGoalModal } from '@townsquare/create-goal-modal';
import { CreateProjectDropdown, useCreateProjectStore, usePreloadProductActivations } from '@townsquare/create-project';
import { useCreateTeamModal } from '@townsquare/create-team-modal';
import { LoadingState } from '@townsquare/loading-state';
import { FullPageViewErrorBoundary } from '@townsquare/monitoring';
import { IconPreviewer } from '@townsquare/project-icon';
import { teamProfileRoute } from '@townsquare/ptc-directory-view';
import { useGeneratePath, useRouterActions } from '@townsquare/router/primitives';
import { getBooleanConfig } from '@townsquare/stat-sig/config';
import { teamHealthRoute } from '@townsquare/team-health-view/routes';
import { tqlQueryFromInput } from '@townsquare/tql/query';
import { ComparatorOperator, Operators } from '@townsquare/tql/types';

import { useGiveKudosModal } from './GiveKudosModal';
import { WriteUpdateForSection } from './WriteUpdateForSection';
import { WriteUpdateForSectionQuery } from './__generated__/WriteUpdateForSectionQuery.graphql';
import { NewButtonItemDescription } from './styles';

export const getNewProjectUpdatesTql = (accountId: string) =>
  tqlQueryFromInput({
    input: [
      { fieldName: 'owner', fieldValue: accountId, comparator: ComparatorOperator.EQUALS },
      { join: Operators.AND },
      { fieldName: 'archived', fieldValue: false, comparator: ComparatorOperator.EQUALS },
      { join: Operators.AND },
      { fieldName: 'status_phase', comparator: ComparatorOperator.EQUALS, fieldValue: 'in_progress' },
    ],
  });

export type NewMenuDropdownProps = {
  onCloseNewMenu: () => void;
  // trigger to re-render the parent popup container, so it can be re-positioned, etc
  updatePopup: () => void;
  queryReference: PreloadedQuery<WriteUpdateForSectionQuery> | undefined | null;
};

export const NewMenuDropdownWrapper = (props: NewMenuDropdownProps) => {
  const [{ route }, routerActions] = useRouter();
  const analytics = useAnalytics();
  const { openGiveKudosModal } = useGiveKudosModal();
  const { openCreateGoalModal } = useCreateGoalModal();
  const { pushTo } = useRouterActions();
  const [createProjectOptions, { openCreateProject }] = useCreateProjectStore();
  const isTeamHealthMonitorEnabled = getBooleanConfig('townsquare_enable_team_health_monitor');
  const [createTeamModal, createTeamModalTrigger] = useCreateTeamModal({
    analyticsCtx: { source: 'newButtonNav' },
    onCreate: team => pushTo(teamProfileRoute, { params: { id: team.id } }),
  });

  const { onCloseNewMenu, updatePopup } = props;
  const maxWidth = 320;

  const teamHealthPath = useGeneratePath(teamHealthRoute);

  // Preload whether a jira site has been activated in this workspace
  // This info is later used for whether the user can access the Jira Epic Picker in the Create Project Dropdown
  usePreloadProductActivations();

  useEffect(() => {
    if (createProjectOptions.open) {
      // Project create dropdown is wider than the current popup width.
      // Need to trigger a re-render so it's not cut off by small browser width
      setTimeout(() => {
        updatePopup();
      }, 0);
    }
  }, [updatePopup, createProjectOptions.open]);

  if (createProjectOptions.open) {
    const { onCancel, onClose, redirectOnSave } = createProjectOptions.options ?? {};
    return (
      <FullPageViewErrorBoundary>
        <Suspense fallback={false}>
          <CreateProjectDropdown
            onClose={projectId => {
              onCloseNewMenu();
              onClose?.(projectId);
            }}
            onCancel={onCancel}
            redirectOnSave={Boolean(redirectOnSave)}
            hasCancelButton
          />
        </Suspense>
      </FullPageViewErrorBoundary>
    );
  }

  return (
    <MenuGroup maxWidth={maxWidth}>
      <Section>
        <ButtonItem
          // TODO Project icons still need to be converted https://product-fabric.atlassian.net.atl.zbizdev.zengel.myshn.net/browse/DSP-1256
          // eslint-disable-next-line @atlaskit/design-system/ensure-design-token-usage
          iconBefore={<IconPreviewer shortName=":watermelon:" color="#4C9AFF" size="large" />}
          testId="new-project-button"
          onClick={() => {
            void analytics.ui('newButtonMenuCreateProject', 'clicked', {
              route: route.name,
            });
            openCreateProject({
              redirectOnSave: true,
              skipQueryLoad: true,
            });
          }}
          shouldDescriptionWrap
          description={
            <NewButtonItemDescription>
              <FormattedMessage
                id="townsquare.navigation.new-button-menu-create-project.description"
                description="Description for the create project option in the new button menu"
                defaultMessage="Share weekly status updates on work with interested parties"
              />
            </NewButtonItemDescription>
          }
        >
          <FormattedMessage
            id="townsquare.navigation.new-button-menu-create-project.button"
            description="Link to open the create project modal in the new button menu"
            defaultMessage="Project"
          />
        </ButtonItem>
        <ButtonItem
          // TODO Project icons still need to be converted https://product-fabric.atlassian.net.atl.zbizdev.zengel.myshn.net/browse/DSP-1256
          // eslint-disable-next-line @atlaskit/design-system/ensure-design-token-usage
          iconBefore={<IconPreviewer shortName=":dart:" color="#FFC400" size="large" />}
          testId="new-goal-button"
          onClick={() => {
            void analytics.ui('newButtonMenuCreateGoal', 'clicked', {
              route: route.name,
            });
            openCreateGoalModal({
              redirectOnSave: true,
            });
            onCloseNewMenu();
          }}
          shouldDescriptionWrap
          description={
            <NewButtonItemDescription>
              <FormattedMessage
                id="townsquare.navigation.new-button-menu-create-goal.description"
                description="Description for the create goal option in the new button menu"
                defaultMessage="Score your goals monthly to keep everyone heading in the same direction"
              />
            </NewButtonItemDescription>
          }
        >
          <FormattedMessage
            id="townsquare.navigation.new-button-menu-create-goal.button"
            description="Link to open the create goal modal in the new button menu"
            defaultMessage="Goal"
          />
        </ButtonItem>
        <ButtonItem
          // TODO Project icons still need to be converted https://product-fabric.atlassian.net.atl.zbizdev.zengel.myshn.net/browse/DSP-1256
          // eslint-disable-next-line @atlaskit/design-system/ensure-design-token-usage
          iconBefore={<IconPreviewer shortName=":running_shirt_with_sash:" size="large" />}
          testId="new-team-button"
          onClick={() => {
            void analytics.ui('newButtonMenuCreateTeam', 'clicked', {
              route: route.name,
            });
            createTeamModalTrigger();
          }}
          shouldDescriptionWrap
          description={
            <NewButtonItemDescription>
              <FormattedMessage
                id="townsquare.navigation.new-button-menu-create-team.description"
                description="Description for the create team option in the new button menu"
                defaultMessage="Stay connected with your teammates and get everyone working in one place"
              />
            </NewButtonItemDescription>
          }
        >
          <FormattedMessage
            id="townsquare.navigation.new-button-menu-create-team.button"
            description="Link to open the create team modal in the new button menu"
            defaultMessage="Team"
          />
        </ButtonItem>
        <ButtonItem
          // TODO Project icons still need to be converted https://product-fabric.atlassian.net.atl.zbizdev.zengel.myshn.net/browse/DSP-1256
          // eslint-disable-next-line @atlaskit/design-system/ensure-design-token-usage
          iconBefore={<IconPreviewer shortName=":clap:" color="#FFEBE6" size="large" />}
          testId="new-kudos-button"
          onClick={() => {
            void analytics.ui('newButtonMenuCreateKudos', 'clicked', {
              route: route.name,
            });
            openGiveKudosModal({ redirectOnSave: true });
          }}
          shouldDescriptionWrap
          description={
            <NewButtonItemDescription>
              <FormattedMessage
                id="townsquare.navigation.new-button-menu-create-kudos.description"
                description="Description for the create kudos option in the new button menu"
                defaultMessage="Say thank you to a peer or team for going above and beyond"
              />
            </NewButtonItemDescription>
          }
        >
          <FormattedMessage
            id="townsquare.navigation.new-button-menu-create-kudos.button"
            description="Link to open the create kudos modal in the new button menu"
            defaultMessage="Kudos"
          />
        </ButtonItem>
        {isTeamHealthMonitorEnabled && (
          <ButtonItem
            // TODO Project icons still need to be converted https://product-fabric.atlassian.net.atl.zbizdev.zengel.myshn.net/browse/DSP-1256
            // eslint-disable-next-line @atlaskit/design-system/ensure-design-token-usage
            iconBefore={<IconPreviewer shortName=":heart:" color="#FFEBE6" size="large" />}
            testId="new-team-health-button"
            onClick={() => {
              onCloseNewMenu();

              routerActions.push(teamHealthPath);
            }}
            shouldDescriptionWrap
            description={
              <NewButtonItemDescription>
                <FormattedMessage
                  id="townsquare.navigation.new-button-menu-team-health-monitor.description"
                  description="Description for the team health monitor in the new button menu"
                  defaultMessage="Assess your team against attributes commonly found among healthy teams"
                />
              </NewButtonItemDescription>
            }
          >
            <FormattedMessage
              id="townsquare.navigation.new-button-menu-team-health-monitor.button"
              description="Link to open the team health monitor in the new button menu"
              defaultMessage="Team Health Monitor"
            />
          </ButtonItem>
        )}
      </Section>
      <Suspense
        fallback={
          <MenuGroup maxWidth={maxWidth}>
            <LoadingState />
          </MenuGroup>
        }
      >
        {props.queryReference && <WriteUpdateForSection {...props} queryReference={props.queryReference} />}
      </Suspense>
      {createTeamModal}
    </MenuGroup>
  );
};
